import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import GuardedRoute from "./components/GuardedRoute";
import Menu from "./components/Menu";
import "./css-imports.ts";
import { useUser } from "./operations/queries/user";
import ChoosePlate from "./pages/ChoosePlate";
import ChoosePress from "./pages/ChoosePress";
import ChooseSide from "./pages/ChooseSide";
import CreateTransaction from "./pages/CreateTransaction";
import PlateSide from "./pages/PlateSide";
import SignIn from "./pages/SignIn";

const App: React.FC = () => {
  const { user, loading } = useUser();

  const isAuthenticated = !!user;

  // Prevent flickering on user load
  if (loading) {
    return null;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            {/* Home => /create */}
            <Redirect exact from="/" to="/create" />

            {/* Sign in page */}
            <Route
              path="/signin"
              exact
              render={() =>
                isAuthenticated ? <Redirect to="/" /> : <SignIn />
              }
            />

            {/* Create transaction page (= choose press) */}
            <GuardedRoute
              path="/create"
              exact
              isAuthenticated={isAuthenticated}
              component={ChoosePress}
            />

            {/* Choose plate page */}
            <GuardedRoute
              path="/create/:pressid"
              exact
              isAuthenticated={isAuthenticated}
              component={ChoosePlate}
            />

            {/* Choose side page */}
            <GuardedRoute
              path="/create/:pressid/:plateid"
              exact
              isAuthenticated={isAuthenticated}
              component={ChooseSide}
            />

            {/* Plate side page */}
            <GuardedRoute
              path="/create/:pressid/:plateid/:side"
              exact
              isAuthenticated={isAuthenticated}
              component={PlateSide}
            />

            {/* Create transaction page */}
            <GuardedRoute
              path="/create/:pressid/:plateid/:side/:operation"
              exact
              isAuthenticated={isAuthenticated}
              component={CreateTransaction}
            />

            {/* Fallback route, redirects to create page */}
            <Route render={() => <Redirect to="/create" />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
