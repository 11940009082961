import { gql, useMutation } from "@apollo/client";
import { compact, concat } from "lodash";
import { CreateTransaction } from "../../models/transaction";
import { GET_FILTER_CLOTH_QUERY } from "../queries/filter-cloths";
import { GET_FILTER_PLATE_QUERY } from "../queries/filter-plates";

export const useCreateTransaction = (
  variables: CreateTransaction,
  plateId: string,
  clothFcidsToRefetch?: (string | undefined)[]
) =>
  useMutation(
    !!variables.newClothRelator
      ? CREATE_TRANSACTION_MUTATION
      : CREATE_UNINSTALL_TRANSACTION_MUTATION,
    {
      variables,
      refetchQueries: concat<any>(
        [{ query: GET_FILTER_PLATE_QUERY, variables: { id: plateId } }],
        compact(clothFcidsToRefetch)?.map((clothFcid) => ({
          query: GET_FILTER_CLOTH_QUERY,
          variables: { fcid: clothFcid },
        }))
      ),
    }
  );

// Replace & install
const CREATE_TRANSACTION_MUTATION = gql`
  mutation CREATE_TRANSACTION_MUTATION(
    $plateId: ID!
    $userId: ID!
    $type: TransactionTypeType!
    $side: TransactionSideType!
    $reason: TransactionReasonType
    $comment: String
    $highlightedAreas: String
    $photo: AzureStorageImageFieldInput
    $newClothRelator: FilterClothRelateToOneForCreateInput
  ) {
    createTransaction(
      data: {
        plate: { connect: { id: $plateId } }
        user: { connect: { id: $userId } }
        type: $type
        side: $side
        reason: $reason
        highlightedAreas: $highlightedAreas
        comment: $comment
        photo: $photo
        newCloth: $newClothRelator
      }
    ) {
      id
    }
  }
`;

// Uninstall
const CREATE_UNINSTALL_TRANSACTION_MUTATION = gql`
  mutation CREATE_UNINSTALL_TRANSACTION_MUTATION(
    $plateId: ID!
    $userId: ID!
    $type: TransactionTypeType!
    $side: TransactionSideType!
    $reason: TransactionReasonType
    $comment: String
    $highlightedAreas: String
    $photo: AzureStorageImageFieldInput
  ) {
    createTransaction(
      data: {
        plate: { connect: { id: $plateId } }
        user: { connect: { id: $userId } }
        type: $type
        side: $side
        reason: $reason
        highlightedAreas: $highlightedAreas
        comment: $comment
        photo: $photo
      }
    ) {
      id
    }
  }
`;
