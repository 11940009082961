import { gql } from "@apollo/client";
import { FilterCloth } from "./filter-cloth";
import { FilterPress } from "./filter-press";

export interface FilterPlate {
  id: string;
  title: string;
  plateIndex: number;
  press: FilterPress;
  cloth?: FilterCloth;
  clothFront?: FilterCloth;
  clothBack?: FilterCloth;
}

export const fragments = {
  filterPlate: gql`
    fragment FilterPlate on FilterPlate {
      id
      title
      plateIndex
    }
  `,
};

export type FilterPlateSide = "front" | "back" | "single";

export const clothFieldMapping = new Map<
  FilterPlateSide,
  "cloth" | "clothBack" | "clothFront"
>([
  ["single", "cloth"],
  ["back", "clothBack"],
  ["front", "clothFront"],
]);
