import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {
  checkmark,
  checkmarkCircle,
  close
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { findClothMode } from "../lib/helpers";
import { clothFieldMapping, FilterPlateSide } from "../models/filter-plate";
import { useGetFilterPlate } from "../operations/queries/filter-plates";

interface ChooseSideProps
  extends RouteComponentProps<{
    pressid: string;
    plateid: string;
  }> {}

const ChooseSide: React.FC<ChooseSideProps> = ({ match }) => {
  const { t } = useTranslation();
  const { loading, error, filterPlate } = useGetFilterPlate(
    match.params.plateid
  );

  const clothMode = filterPlate?.plateIndex
    ? findClothMode(
        filterPlate?.press.clothModeConfig || null,
        filterPlate.plateIndex
      )
    : null;

  const rows: Array<{
    label: string;
    items: FilterPlateSide[];
    enabled: boolean;
  }> = [
    {
      label: t("single-cloth"),
      items: ["single"],
      enabled: clothMode === "single",
    },
    {
      label: t("dual-cloth"),
      items: ["front", "back"],
      enabled: clothMode === "dual",
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Choose side</IonTitle>

          <IonButtons slot="end">
            <IonButton
              routerLink={`/create/${match.params.pressid}`}
              routerDirection="back"
            >
              Back
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          {loading ? (
            <IonItem lines="full">
              <IonLabel>Loading</IonLabel>
              <IonSpinner />
            </IonItem>
          ) : !!error ? (
            <IonItem lines="full">
              <IonLabel className="ion-text-wrap">{error.toString()}</IonLabel>
            </IonItem>
          ) : !!filterPlate ? (
            rows.map(({ label, items, enabled }) => (
              <IonItemGroup key={label}>
                <IonItemDivider color={enabled ? "primary" : "medium"}>
                  <IonIcon icon={enabled ? checkmark : close} />
                  <IonLabel>{label}</IonLabel>
                </IonItemDivider>
                {items.map((side) => {
                  const filterCloth =
                    filterPlate?.[clothFieldMapping.get(side)!];

                  return (
                    <IonItem
                      key={side}
                      lines="full"
                      routerLink={`${match.url}/${side}`}
                      routerDirection="forward"
                    >
                      {t(side)}

                      {/* Install status */}
                      {!!filterCloth && (
                        <IonIcon
                          slot="end"
                          color="success"
                          icon={checkmarkCircle}
                        />
                      )}
                    </IonItem>
                  );
                })}
              </IonItemGroup>
            ))
          ) : (
            <IonItem lines="full">No filter plate found</IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ChooseSide;
