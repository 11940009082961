import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonNote,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { format, formatDistanceToNow } from "date-fns";
import { arrowDown, arrowUp, swapVertical } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { findClothMode, smartTrim } from "../lib/helpers";
import { clothFieldMapping, FilterPlateSide } from "../models/filter-plate";
import { useGetFilterPlate } from "../operations/queries/filter-plates";

interface PlateSideProps
  extends RouteComponentProps<{
    pressid: string;
    plateid: string;
    side: FilterPlateSide;
  }> {}

const PlateSide: React.FC<PlateSideProps> = ({ match }) => {
  const { t } = useTranslation();
  const { pressid, plateid, side } = match.params;

  const { loading, error, filterPlate } = useGetFilterPlate(plateid);

  // Get currently installed filter cloth
  const filterCloth = filterPlate?.[clothFieldMapping.get(side)!];

  // Date calculations
  const installedTime = !!filterCloth?.installedTime
    ? new Date(filterCloth?.installedTime)
    : undefined;

  let operationTime = undefined;
  if (!!installedTime) {
    // Installed -> now
    operationTime = formatDistanceToNow(installedTime);
  }

  // Calculate if install should be allowed
  const allowInstall = {
    single: !filterPlate?.clothBack && !filterPlate?.clothFront,
    front: !filterPlate?.cloth,
    back: !filterPlate?.cloth,
  };

  const clothMode = filterPlate?.plateIndex
    ? findClothMode(
        filterPlate?.press.clothModeConfig || null,
        filterPlate.plateIndex
      )
    : null;

  const sideMatchesClothMode = {
    single: clothMode === "single",
    front: clothMode === "dual",
    back: clothMode === "dual",
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>
            {loading ? (
              <IonSpinner />
            ) : !error ? (
              <>
                {filterPlate?.title} ({t(side)})
              </>
            ) : (
              "Error"
            )}
          </IonTitle>

          <IonButtons slot="end">
            <IonButton
              routerLink={`/create/${pressid}/${plateid}`}
              routerDirection="back"
            >
              Back
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {/* Handle apollo error */}
        {!!error ? (
          <div className="ion-padding">
            <p>{error.message}</p>
          </div>
        ) : (
          // Content
          <>
            {/* Actions */}
            <div className="ion-padding">
              {!!filterCloth ? (
                <>
                  <IonButton
                    routerLink={`${match.url}/replace`}
                    routerDirection="forward"
                    disabled={
                      !allowInstall[side] || !sideMatchesClothMode[side]
                    }
                  >
                    Replace
                    <IonIcon slot="end" icon={swapVertical} />
                  </IonButton>

                  <IonButton
                    routerLink={`${match.url}/uninstall`}
                    routerDirection="forward"
                    color="danger"
                  >
                    Uninstall
                    <IonIcon slot="end" icon={arrowDown} />
                  </IonButton>
                </>
              ) : (
                <>
                  <IonButton
                    routerLink={`${match.url}/install`}
                    routerDirection="forward"
                    color="success"
                    disabled={
                      !allowInstall[side] || !sideMatchesClothMode[side]
                    }
                    expand="block"
                  >
                    Install
                    <IonIcon slot="end" icon={arrowUp} />
                  </IonButton>

                  {/* Disabled install hints */}
                  {!sideMatchesClothMode[side] ? (
                    <IonNote className="ion-block">
                      <p>{t("not-applicable-hint")}</p>
                    </IonNote>
                  ) : (
                    !allowInstall[side] && (
                      <IonNote className="ion-block">
                        <p>{t("uninstall-first-hint")}</p>
                      </IonNote>
                    )
                  )}
                </>
              )}
            </div>

            {/* Currently installed filter cloth */}
            {!!filterCloth && (
              <IonList>
                <IonListHeader>
                  <IonLabel>Currently installed filter cloth</IonLabel>
                </IonListHeader>

                <IonItem lines="full">
                  <IonLabel>
                    <h2>Filter cloth ID</h2>
                    <p>{smartTrim(filterCloth.fcid, 16)}</p>
                  </IonLabel>
                </IonItem>

                {!!filterCloth.modelNumber && (
                  <IonItem lines="full">
                    <IonLabel>
                      <h2>Model number</h2>
                      <p>{filterCloth.modelNumber}</p>
                    </IonLabel>
                  </IonItem>
                )}

                {!!installedTime && (
                  <IonItem lines="full">
                    <IonLabel>
                      <h2>Installed at</h2>
                      <p>{format(installedTime, "PPpp")}</p>
                    </IonLabel>
                  </IonItem>
                )}

                {!!operationTime && (
                  <IonItem lines="full">
                    <IonLabel>
                      <h2>Operation time</h2>
                      <p>{operationTime}</p>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PlateSide;
