import { gql, useQuery } from "@apollo/client";
import { fragments as customerFragments } from "../../models/customer";
import { fragments as filterPlateFragments } from "../../models/filter-plate";
import { FilterPress, fragments } from "../../models/filter-press";
import { fragments as locationFragments } from "../../models/location";

export const GET_ALL_FILTER_PRESSES_QUERY = gql`
  query FilterPressesQuery {
    filterPresses {
      ...FilterPress
      location {
        ...Location
        customer {
          ...Customer
        }
      }
    }
  }
  ${fragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
`;

export const useGetAllFilterPresses = () => {
  const { loading, error, data } = useQuery<{
    filterPresses: FilterPress[];
  }>(GET_ALL_FILTER_PRESSES_QUERY);

  const filterPresses = data?.filterPresses;

  return {
    loading,
    error,
    filterPresses,
  };
};

export const GET_FILTER_PRESS_QUERY = gql`
  query FILTER_PRESS_QUERY($id: ID!) {
    filterPress(where: { id: $id }) {
      ...FilterPress
      location {
        ...Location
        customer {
          ...Customer
        }
      }
      plates {
        ...FilterPlate
        press {
          ...FilterPress
        }
      }
    }
  }
  ${fragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
  ${filterPlateFragments.filterPlate}
  ${fragments.filterPress}
`;

export const useGetFilterPress = (id: string) => {
  const { loading, error, data } = useQuery<{
    filterPress: FilterPress;
  }>(GET_FILTER_PRESS_QUERY, {
    variables: {
      id,
    },
  });

  const filterPress = data?.filterPress;

  return {
    loading,
    error,
    filterPress,
  };
};
