import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import { scan } from "ionicons/icons";
import React from "react";
import { RouteComponentProps } from "react-router";
import NDEFReaderModal from "../components/NDEFReaderModal";
import { useClothShortcut } from "../lib/useClothShortcut";
import { useErrorToast } from "../lib/useErrorToast";
import { FilterPress } from "../models/filter-press";
import { Location } from "../models/location";
import { useGetAllFilterPresses } from "../operations/queries/filter-presses";

interface ChoosePressProps extends RouteComponentProps {}

const ChoosePress: React.FC<ChoosePressProps> = ({ history }) => {
  // Get filter presses from API
  const { loading, error, filterPresses } = useGetAllFilterPresses();

  // Sort filter presses alphabetically
  const sortedFilterPresses = !!filterPresses?.length
    ? [...filterPresses].sort((a, b) =>
        a.title.localeCompare(b.title, "de", {
          numeric: true,
        })
      )
    : filterPresses;

  const locationsMap = !!sortedFilterPresses
    ? [...sortedFilterPresses]
        .sort((a, b) => {
          // No location => item should be in the back
          if (!a.location) return 1;
          if (!b.location) return -1;
          // Location present, sort alphabetically
          return a.location.name.localeCompare(b.location.name);
        })
        .reduce(
          (intermediatelocationsMap, filterPress) =>
            !!filterPress.location
              ? intermediatelocationsMap.set(filterPress.location, [
                  ...(intermediatelocationsMap.get(filterPress.location) || []),
                  filterPress,
                ])
              : intermediatelocationsMap,
          new Map<Location, FilterPress[]>()
        )
    : null;

  // Handle shortcut route to plate side
  const { setShortcutClothId } = useClothShortcut(history);
  const [presentNDEFReaderModal, dismissNDEFReaderModal] = useIonModal(
    NDEFReaderModal,
    {
      onDismiss: () => {
        dismissNDEFReaderModal();
      },
      setClothId: setShortcutClothId,
    }
  );

  const errorToast = useErrorToast();
  const handlePresentNDEFReaderModal = () => {
    if (!("NDEFReader" in window)) {
      errorToast(`Error! Web NFC is not supported`);
      return;
    }

    presentNDEFReaderModal();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>Choose filter press</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={handlePresentNDEFReaderModal}>
              <IonIcon slot="icon-only" icon={scan} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          {loading ? (
            <IonItem lines="full">
              <IonLabel>Loading</IonLabel>
              <IonSpinner />
            </IonItem>
          ) : !!error ? (
            <IonItem lines="full">
              <IonLabel className="ion-text-wrap">{error.toString()}</IonLabel>
            </IonItem>
          ) : !!locationsMap?.size ? (
            Array.from(locationsMap).map(([location, filterPresses]) => (
              <React.Fragment key={location.id}>
                <IonItemGroup>
                  <IonItemDivider>
                    <IonLabel>{location.name}</IonLabel>
                  </IonItemDivider>
                  {filterPresses.map((filterPress) => (
                    <IonItem
                      lines="full"
                      key={filterPress.id}
                      routerLink={`/create/${filterPress?.id}`}
                      routerDirection="forward"
                    >
                      <IonLabel>
                        {/* Title */}
                        <h2>{filterPress.title}</h2>

                        {/* Model number */}
                        {!!filterPress.modelNumber && (
                          <p>{filterPress.modelNumber}</p>
                        )}
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonItemGroup>
              </React.Fragment>
            ))
          ) : (
            <IonItem lines="full">No filter presses found</IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ChoosePress;
