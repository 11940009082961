import { useIonToast } from "@ionic/react";

export const useErrorToast = () => {
  const [presentToast] = useIonToast();

  return (message: string) => {
    console.error(message);
    presentToast({
      color: "danger",
      duration: 2500,
      message,
    });
  };
};
