import { gql, useQuery } from "@apollo/client";
import { User } from "../../models/user";

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    authenticatedItem {
      ... on User {
        id
        email
        name
      }
    }
  }
`;

export const useUser = () => {
  const { loading, error, data } = useQuery<{
    authenticatedItem: User;
  }>(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const user = data?.authenticatedItem;

  return {
    loading,
    error,
    user,
  };
};
