import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";
import { logOutOutline, logOutSharp, swapVertical } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import { useSignOut } from "../operations/mutations/user";
import { useUser } from "../operations/queries/user";
import "./Menu.css";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  attributes?: object;
}

const Menu: React.FC = () => {
  const location = useLocation();
  const { user, loading } = useUser();
  const [signOut] = useSignOut();

  const appPages: AppPage[] = [
    {
      title: "Create transaction",
      url: "/create",
      iosIcon: swapVertical,
      mdIcon: swapVertical,
    },
    {
      title: "Sign Out",
      url: "/signout",
      iosIcon: logOutOutline,
      mdIcon: logOutSharp,
      attributes: {
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          signOut();
        },
      },
    },
  ];

  // Don't render if not logged in
  if (!user || loading) {
    return null;
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="menu-list">
          <IonListHeader>
            <img
              className="menu__logo"
              src="/assets/andritz-logo.svg"
              alt="Andritz"
            />
          </IonListHeader>
          <IonListHeader>{user?.name}</IonListHeader>
          <IonNote>{user?.email}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname.startsWith(appPage.url) ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  {...appPage.attributes}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
