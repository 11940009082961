import { gql } from "@apollo/client";
import { Location } from "./location";

export interface Customer {
  id: string;
  name: string;
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  address?: string;
  locations?: Location[];
}

export const fragments = {
  customer: gql`
    fragment Customer on Customer {
      id
      name
      streetAddress
      postalCode
      city
      country
      address
    }
  `,
};
