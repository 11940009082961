import { gql, useQuery } from "@apollo/client";
import { FilterCloth, fragments } from "../../models/filter-cloth";
import { fragments as filterPlateFragments } from "../../models/filter-plate";
import { fragments as filterPressFragments } from "../../models/filter-press";
import { fragments as locationFragments } from "../../models/location";
import { fragments as customerFragments } from "../../models/customer";

export const GET_ALL_FILTER_CLOTHS_QUERY = gql`
  query FilterClothsQuery {
    filterCloths {
      ...FilterCloth
      plate {
        ...FilterPlate
        press {
          ...FilterPress
        }
      }
    }
  }
  ${fragments.filterCloth}
  ${filterPlateFragments.filterPlate}
  ${filterPressFragments.filterPress}
`;

export const useGetAllFilterCloths = () => {
  const { loading, error, data } = useQuery<{
    filterCloths: FilterCloth[];
  }>(GET_ALL_FILTER_CLOTHS_QUERY);

  const filterCloths = data?.filterCloths;

  return {
    loading,
    error,
    filterCloths,
  };
};

// Only supports FCID right now. Should be generalized as soon as ID is needed
export const GET_FILTER_CLOTH_QUERY = gql`
  query FILTER_CLOTH_QUERY($fcid: String!) {
    filterCloth(where: { fcid: $fcid }) {
      ...FilterCloth
      plate {
        ...FilterPlate
        press {
          ...FilterPress
          location {
            ...Location
            customer {
              ...Customer
            }
          }
        }
      }
    }
  }
  ${fragments.filterCloth}
  ${filterPlateFragments.filterPlate}
  ${filterPressFragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
`;

export const useGetFilterClothByFcid = (
  fcid: string,
  skip: boolean = false
) => {
  const { loading, error, data } = useQuery<{
    filterCloth: FilterCloth;
  }>(GET_FILTER_CLOTH_QUERY, {
    variables: {
      fcid,
    },
    skip,
  });

  const filterCloth = data?.filterCloth;

  return {
    loading,
    error,
    filterCloth,
  };
};
