import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { useState } from "react";

export function usePhoto() {
  const [photo, setPhoto] = useState<Photo>();

  const takePhoto = async () => {
    let photo: Photo | undefined;

    try {
      photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 85,
      });
    } catch (e) {
      console.warn(e);
    }

    !!photo && setPhoto(photo);
  };

  return {
    photo,
    takePhoto,
  };
}
