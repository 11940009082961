import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { xor } from "lodash";
import { useState } from "react";
import { parseHighlightedAreas } from "../lib/helpers";
import { Area } from "../models/filter-cloth";
import Heatmap from "./Heatmap";

interface ChooseHighlightsModalProps {
  highlightedAreas?: string;
  setHighlightedAreas: React.Dispatch<React.SetStateAction<string | undefined>>;
  onDismiss: () => void;
}

const ChooseHighlightsModal: React.FC<ChooseHighlightsModalProps> = ({
  onDismiss,
  highlightedAreas,
  setHighlightedAreas,
}) => {
  // Selected areas in heatmap
  const [highlightedHeatmapAreas, setHighlightedHeatmapAreas] = useState<
    Area[]
  >(highlightedAreas ? parseHighlightedAreas(highlightedAreas) : []);

  // Dismiss = close modal
  const dismiss = () => {
    onDismiss();
  };

  const handleOkButtonClick: React.MouseEventHandler<HTMLIonButtonElement> =
    () => {
      // Write result to parent state
      setHighlightedAreas(
        highlightedHeatmapAreas.length
          ? highlightedHeatmapAreas.sort().join(",")
          : undefined
      );

      // Close modal
      dismiss();
    };

  const toggleHeatmapArea = (area: Area) => {
    setHighlightedHeatmapAreas(xor(highlightedHeatmapAreas, [area]).sort());
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Choose highlighted areas</IonTitle>

          {/* Close modal button */}
          <IonButtons slot="end" onClick={() => dismiss()}>
            <IonButton>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="ion-padding">
          <Heatmap
            toggleHeatmapArea={toggleHeatmapArea}
            highlightedHeatmapAreas={highlightedHeatmapAreas}
          />

          {/* Submit button */}
          <div className="heatmap-submit ion-margin-top">
            <IonButton onClick={handleOkButtonClick} expand="block">
              Ok
            </IonButton>
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default ChooseHighlightsModal;
