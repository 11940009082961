import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { useErrorToast } from "../lib/useErrorToast";
import "./NDEFReaderModal.css";

interface NDEFReaderModalProps {
  setClothId: React.Dispatch<React.SetStateAction<string>>;
  onDismiss: () => void;
}

const NDEFReaderModal: React.FC<NDEFReaderModalProps> = ({
  onDismiss,
  setClothId,
}) => {
  const [scanning, setScanning] = useState<boolean>(false);
  const [scanningDenied, setScanningDenied] = useState<boolean>(false);
  const [showScanButton, setShowScanButton] = useState<boolean>(false);
  const [abortController] = useState<AbortController>(new AbortController());
  const errorToast = useErrorToast();
  const [presentToast] = useIonToast();

  // Dismiss = close modal and abort scanning
  const dismiss = () => {
    setTimeout(() => {
      abortController.abort();
    }, 1000);
    onDismiss();
  };

  // Set up scanning on initial modal show
  useEffect(() => {
    if (!("NDEFReader" in window)) {
      errorToast(`Error! Web NFC is not supported`);
      return;
    }

    // Set up abort controller first
    abortController.signal.onabort = () => {
      setScanning(false);
    };

    // Check if scanning permission has been given.
    async function startScanningIfPermitted() {
      const permissionStatus = await checkNFCPermissionStatus();

      if (permissionStatus === "granted") {
        // NFC access was previously granted, so we can start NFC scanning now.
        startScanning();
      } else if (permissionStatus === "denied") {
        // NFC access was previously denied, show an error message
        setScanningDenied(true);
      } else {
        // NFC access wasn't yet asked for, show a "scan" button.
        setShowScanButton(true);
      }
    }

    startScanningIfPermitted();
  }, []);

  const handleScanButtonClick: React.MouseEventHandler<
    HTMLIonButtonElement
  > = () => {
    startScanning();
    setShowScanButton(false);
  };

  const checkNFCPermissionStatus = async () => {
    try {
      const nfcPermissionStatus = await navigator.permissions.query({
        // @ts-ignore
        name: "nfc",
      });
      return nfcPermissionStatus.state;
    } catch (error) {
      errorToast(`Error! ${error}`);
      return;
    }
  };

  const startScanning = async () => {
    // If we're already scanning, skip everything
    if (scanning) {
      return;
    }

    try {
      // Start NFC scanning and prompt user if needed
      const ndef = new NDEFReader();

      // At this point, the NFC permission has been given => start scanning
      await ndef.scan({ signal: abortController.signal });
      setScanning(true);

      ndef.onreadingerror = () => {
        errorToast("Error! Cannot read data from the NFC tag");
      };

      ndef.onreading = ({ message }) => {
        // Check if tag contains content
        if (!message.records.length) {
          errorToast("Error! Invalid NFC tag");
          return;
        }

        // Extract tag content
        const [{ recordType, data, encoding }] = message.records;

        // Check if tag is of the right format
        // TODO: Serial number regex
        if (recordType !== "text" || !data) {
          errorToast("Error! Invalid NFC tag");
          return;
        }

        // Decode data buffer
        const textDecoder = new TextDecoder(encoding);
        const id = textDecoder.decode(data);

        // Write result to parent state
        setClothId(id);

        // Notify user
        presentToast({
          color: "success",
          duration: 2500,
          message: "Filter cloth successfully scanned",
        });

        // Close modal
        dismiss();
      };
    } catch (error) {
      errorToast(`Error! Scan failed to start: ${error}`);

      // If it's permission denied, update the state so we can show the error message
      const permissionStatus = await checkNFCPermissionStatus();
      if (permissionStatus === "denied") {
        setScanningDenied(true);
      }
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Scan Filter Cloth</IonTitle>

          {/* Close modal button */}
          <IonButtons slot="end" onClick={() => dismiss()}>
            <IonButton>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="ndef-reader__content">
          <img
            src="/assets/nfc.svg"
            alt=""
            className={classnames("ndef-reader__tag", {
              animated: scanning,
            })}
          />

          {/* Scanning in progress message */}
          {scanning && (
            <IonChip color="primary" className="ion-margin-top">
              <IonLabel>Waiting for NFC tag &hellip;</IonLabel>
            </IonChip>
          )}

          {/* Scanning denied message */}
          {scanningDenied && (
            <IonChip color="danger" className="ion-margin-top">
              <IonLabel>NFC permission denied</IonLabel>
            </IonChip>
          )}

          {/* Initialize scan button */}
          {showScanButton && (
            <IonButton
              onClick={handleScanButtonClick}
              className="ion-margin-top"
            >
              Start scanning
            </IonButton>
          )}
        </div>
      </IonContent>

      {/* Optional activity indicator */}
      {scanning && (
        <IonFooter>
          <IonProgressBar type="indeterminate"></IonProgressBar>
        </IonFooter>
      )}
    </>
  );
};

export default NDEFReaderModal;
