import { gql, useQuery } from "@apollo/client";
import { fragments as customerFragments } from "../../models/customer";
import { fragments as filterClothFragments } from "../../models/filter-cloth";
import { FilterPlate, fragments } from "../../models/filter-plate";
import { fragments as filterPressFragments } from "../../models/filter-press";
import { fragments as locationFragments } from "../../models/location";

export const GET_ALL_FILTER_PLATES_QUERY = gql`
  query FilterPlatesQuery {
    filterPlates {
      ...FilterPlate
      press {
        ...FilterPress
      }
    }
  }
  ${fragments.filterPlate}
  ${filterPressFragments.filterPress}
`;

export const useGetAllFilterPlates = () => {
  const { loading, error, data } = useQuery<{
    filterPlates: FilterPlate[];
  }>(GET_ALL_FILTER_PLATES_QUERY);

  const filterPlates = data?.filterPlates;

  return {
    loading,
    error,
    filterPlates,
  };
};

export const GET_FILTER_PLATES_FOR_PRESS_QUERY = gql`
  query FILTER_PLATES_FOR_PRESS_QUERY($pressId: ID!) {
    filterPlates(where: { press: { id: { equals: $pressId } } }) {
      ...FilterPlate
      press {
        ...FilterPress
      }
      cloth {
        ...FilterCloth
      }
      clothFront {
        ...FilterCloth
      }
      clothBack {
        ...FilterCloth
      }
    }
  }
  ${filterClothFragments.filterCloth}
  ${fragments.filterPlate}
  ${filterPressFragments.filterPress}
`;

export const useGetFilterPlatesForPress = (pressId: string) => {
  const { loading, error, data } = useQuery<{
    filterPlates: FilterPlate[];
  }>(GET_FILTER_PLATES_FOR_PRESS_QUERY, {
    variables: {
      pressId,
    },
  });

  const filterPlates = data?.filterPlates;

  return {
    loading,
    error,
    filterPlates,
  };
};

export const GET_FILTER_PLATE_QUERY = gql`
  query FILTER_PLATE_QUERY($id: ID!) {
    filterPlate(where: { id: $id }) {
      ...FilterPlate
      press {
        ...FilterPress
        location {
          ...Location
          customer {
            ...Customer
          }
        }
      }
      cloth {
        ...FilterCloth
      }
      clothFront {
        ...FilterCloth
      }
      clothBack {
        ...FilterCloth
      }
    }
  }
  ${filterClothFragments.filterCloth}
  ${fragments.filterPlate}
  ${filterPressFragments.filterPress}
  ${locationFragments.location}
  ${customerFragments.customer}
`;

export const useGetFilterPlate = (id: string, skip: boolean = false) => {
  const { loading, error, data } = useQuery<{
    filterPlate: FilterPlate;
  }>(GET_FILTER_PLATE_QUERY, {
    variables: {
      id,
    },
    skip,
  });

  const filterPlate = data?.filterPlate;

  return {
    loading,
    error,
    filterPlate,
  };
};
