import { gql } from "@apollo/client";
import { Customer } from "./customer";
import { FilterPress } from "./filter-press";

export interface Location {
  id: string;
  name: string;
  streetAddress?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  address?: string;
  customer?: Customer;
  presses?: FilterPress[];
}

export const fragments = {
  location: gql`
    fragment Location on Location {
      id
      name
      streetAddress
      postalCode
      city
      country
      address
    }
  `,
};
