import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { FilterPlate } from "../models/filter-plate";
import { useGetFilterPlatesForPress } from "../operations/queries/filter-plates";
import { alertCircle } from "ionicons/icons";

const sortFilterPlates = (filterPlates: FilterPlate[]) =>
  [...filterPlates]?.sort((a, b) => a.plateIndex - b.plateIndex);

interface ChoosePlateProps
  extends RouteComponentProps<{
    pressid: string;
  }> {}

const ChoosePlate: React.FC<ChoosePlateProps> = ({ match }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [searchedFilterPlates, setSearchedFilterPlates] =
    useState<FilterPlate[]>();

  // Get filter plates from API
  const { loading, error, filterPlates } = useGetFilterPlatesForPress(
    match.params.pressid
  );

  // Search logic
  useEffect(() => {
    let tempSearchResult =
      filterPlates &&
      [...filterPlates].filter((plate) => plate.title.includes(searchQuery));
    setSearchedFilterPlates(tempSearchResult);
  }, [filterPlates, searchQuery]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Choose filter plate</IonTitle>

          <IonButtons slot="end">
            <IonButton routerLink="/create" routerDirection="back">
              Back
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {/* List search */}
        <IonSearchbar
          value={searchQuery}
          onIonChange={(e) => setSearchQuery(e.detail.value!)}
        />

        <IonList>
          {loading ? (
            <IonItem lines="full">
              <IonLabel>Loading</IonLabel>
              <IonSpinner />
            </IonItem>
          ) : !!error ? (
            <IonItem lines="full">
              <IonLabel className="ion-text-wrap">{error.toString()}</IonLabel>
            </IonItem>
          ) : !!searchedFilterPlates?.length ? (
            sortFilterPlates(searchedFilterPlates).map((filterPlate) => (
              <IonItem
                button
                key={filterPlate.id}
                lines="full"
                routerLink={`${match.url}/${filterPlate?.id}`}
                routerDirection="forward"
              >
                <IonLabel>{filterPlate.title}</IonLabel>
                {/* Plate indicator */}
                {/* TODO: move into backend */}
                {!filterPlate.clothBack &&
                  !filterPlate.clothFront &&
                  !filterPlate.cloth && (
                    <IonIcon slot="end" icon={alertCircle} color="danger" />
                  )}
              </IonItem>
            ))
          ) : (
            <IonItem lines="full">No filter plates found</IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ChoosePlate;
