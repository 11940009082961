import { gql } from "@apollo/client";
import { FilterPlate } from "./filter-plate";
import { Location } from "./location";

export interface FilterPress {
  id: string;
  title: string;
  isActive: boolean;
  modelNumber: string;
  serialNumber: string;
  numberOfPlates: number;
  clothModeConfig:
    | {
        plateIndex: number;
        clothMode: "dual" | "single";
      }[]
    | null;
  location?: Location;
  plates?: FilterPlate[];
}

export const fragments = {
  filterPress: gql`
    fragment FilterPress on FilterPress {
      id
      title
      isActive
      modelNumber
      serialNumber
      numberOfPlates
      clothModeConfig
    }
  `,
};
