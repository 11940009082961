import { gql, useMutation } from "@apollo/client";
import { CURRENT_USER_QUERY } from "../queries/user";

// Fetch CSRF token for interacting with the Nextauth REST API
export async function getCsrfToken() {
  try {
    const baseUrl = process.env.REACT_APP_NEXTAUTH_PATH;

    // baseurl env var has to be defined
    if (!baseUrl) {
      return;
    }

    // Fetch and return CSRF token
    const res = await fetch(`${baseUrl}/csrf`, {
      credentials: "include",
    });
    const data = await res.json();
    return data?.csrfToken;
  } catch (e) {
    console.error(e);
  }
}

// Sign in (via NextAuth REST API)
export const signIn = async () => {
  const baseUrl = process.env.REACT_APP_NEXTAUTH_PATH;
  const csrfToken = await getCsrfToken();

  // baseurl and csrf token have to be defined
  if (!baseUrl || !csrfToken) {
    return {
      status: "error",
    };
  }

  const callbackUrl = window.location.origin;
  let data, error;

  try {
    const res = await fetch(
      `${baseUrl}/signin/azure-ad?${new URLSearchParams({ callbackUrl })}`,
      {
        credentials: "include",
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // @ts-expect-error
        body: new URLSearchParams({
          csrfToken,
          callbackUrl,
          json: true,
        }),
      }
    );

    data = await res.json();
  } catch (e) {
    console.error(e);
    error = e;
  }

  // Redirect to azure signin flow
  const url = data?.url;
  if (!!url) {
    window.location.href = url;
    // If url contains a hash, the browser does not reload the page. We reload manually
    if (url.includes("#")) window.location.reload();

    return {
      status: "redirect",
    };
  }

  return {
    status: "error",
    error,
  };
};

// Sign out
const SIGNOUT_MUTATION = gql`
  mutation SIGNOUT_MUTATION {
    endSession
  }
`;

export const useSignOut = () => {
  return useMutation(SIGNOUT_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
  });
};
