import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonToast,
  useIonViewDidLeave,
} from "@ionic/react";
import imageBlobReduce from "image-blob-reduce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import ChooseHighlightsModal from "../components/ChooseHighlightsModal";
import NDEFReaderModal from "../components/NDEFReaderModal";
import { smartTrim } from "../lib/helpers";
import { useErrorToast } from "../lib/useErrorToast";
import { usePhoto } from "../lib/usePhoto";
import { clothFieldMapping, FilterPlateSide } from "../models/filter-plate";
import { Reason } from "../models/transaction";
import { useCreateTransaction } from "../operations/mutations/transaction";
import { useGetFilterClothByFcid } from "../operations/queries/filter-cloths";
import { useGetFilterPlate } from "../operations/queries/filter-plates";
import { useUser } from "../operations/queries/user";

interface CreateTransactionProps
  extends RouteComponentProps<{
    pressid: string;
    plateid: string;
    side: FilterPlateSide;
    operation: "replace" | "install" | "uninstall";
  }> {}

const CreateTransaction: React.FC<CreateTransactionProps> = ({
  match,
  history,
}) => {
  const errorToast = useErrorToast();
  const [presentToast] = useIonToast();
  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState(false);
  const [newClothId, setNewClothId] = useState<string>("");
  const [reason, setReason] = useState<Reason>();
  const [highlightedAreas, setHighlightedAreas] = useState<string>();
  const [comment, setComment] = useState<string>();
  const { user } = useUser();

  // Reset state on leaving the view
  useIonViewDidLeave(() => {
    setNewClothId("");
    setReason(undefined);
    setComment(undefined);
    setHighlightedAreas(undefined);
    setShowLoading(false);
  });

  const { operation, pressid, plateid, side } = match.params;

  // Get filter plate
  const {
    filterPlate,
    loading: filterPlateLoading,
    // TODO: handle this error case
    error: filterPlateError,
  } = useGetFilterPlate(plateid);

  // Get possible currently installed filter cloth
  const oldFilterCloth = filterPlate?.[clothFieldMapping.get(side)!];

  // Get new cloth once it's provided
  const { filterCloth: newFilterCloth } = useGetFilterClothByFcid(
    newClothId,
    !newClothId
  );

  // Set up relator structure for new cloth
  const newClothRelator =
    operation === "uninstall"
      ? undefined
      : !!newFilterCloth
      ? {
          connect: { id: newFilterCloth.id },
        }
      : {
          create: { fcid: newClothId },
        };

  // Handle photo
  const { photo, takePhoto } = usePhoto();

  // Read taken photo as blob
  const [photoBlob, setPhotoBlob] = useState<Blob>();
  useEffect(() => {
    const readPhoto = async () => {
      if (!!photo?.webPath) {
        const response = await fetch(photo.webPath);
        const blob = await response.blob();
        const resizedBlob = await imageBlobReduce().toBlob(blob, { max: 1920 });

        setPhotoBlob(resizedBlob);
      }
    };
    readPhoto();
  }, [photo]);

  // The transaction mutation
  const [
    createTransaction,
    {
      data: createTransactionData,
      loading: createTransactionLoading,
      error: createTransactionError,
    },
  ] = useCreateTransaction(
    {
      type: operation,
      side,
      plateId: plateid,
      userId: user!.id,
      newClothRelator,
      reason,
      comment,
      highlightedAreas,
      photo: !!photoBlob
        ? {
            upload: photoBlob,
          }
        : undefined,
    },
    plateid,
    [newClothId, oldFilterCloth?.fcid]
  );

  // Mutation error toast
  useEffect(() => {
    if (!!createTransactionError) {
      setShowLoading(false);
      errorToast(createTransactionError.message);
    }
  }, [createTransactionError]);

  // Loader
  useEffect(() => {
    setShowLoading(createTransactionLoading && !createTransactionError);
  }, [createTransactionLoading, createTransactionError]);

  // Success
  useEffect(() => {
    if (!!createTransactionData && !createTransactionError) {
      history.push(`/create/${pressid}/${plateid}/${side}`);
      presentToast({
        color: "success",
        duration: 2500,
        message: `${t(operation)} successful`,
      });
    }
  }, [createTransactionData, createTransactionError]);

  async function handleSubmit() {
    // Validation should be handled by backend
    try {
      await createTransaction();
    } catch (e) {
      // already handled via `createTransactionError`
    }
  }

  // Handle NDEF reader
  const [presentNDEFReaderModal, dismissNDEFReaderModal] = useIonModal(
    NDEFReaderModal,
    {
      onDismiss: () => {
        dismissNDEFReaderModal();
      },
      setClothId: setNewClothId,
    }
  );

  const handlePresentNDEFReaderModal = () => {
    if (!("NDEFReader" in window)) {
      errorToast(`Error! Web NFC is not supported`);
      return;
    }

    presentNDEFReaderModal();
  };

  // Handle highlighted areas
  const [presentChooseHighlightsModal, dismissChooseHighlightsModal] =
    useIonModal(ChooseHighlightsModal, {
      onDismiss: () => {
        dismissChooseHighlightsModal();
      },
      setHighlightedAreas,
      highlightedAreas,
    });

  const handlePresentChooseHighlightsModal = () => {
    presentChooseHighlightsModal();
  };

  // Light submit validation (real validation is done in backend)
  // Exception: Reason validation is only done here. It shouldn't be empty, but it's not critical.
  const canSubmit =
    {
      install: !!newClothId,
      replace: !!newClothId && !!reason,
      uninstall: !!reason,
    }[operation] &&
    !filterPlateLoading &&
    !filterPlateError;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>{t(operation)} filter cloth</IonTitle>

          <IonButtons slot="end">
            <IonButton
              routerLink={`/create/${pressid}/${plateid}/${side}`}
              routerDirection="back"
            >
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          {/* Cloth ID */}
          {operation !== "uninstall" && (
            <IonItem button lines="full" onClick={handlePresentNDEFReaderModal}>
              <IonLabel>
                <h2>Filter cloth ID</h2>
                {!!newClothId && <p>{smartTrim(newClothId, 16)}</p>}
              </IonLabel>
              <IonButton
                fill="outline"
                slot="end"
                style={{ pointerEvents: "none" }}
              >
                Scan cloth
              </IonButton>
            </IonItem>
          )}

          {/* Reason select */}
          {operation !== "install" && (
            <IonItem lines="full">
              <IonLabel>Reason</IonLabel>
              <IonSelect
                value={reason}
                onIonChange={(e) => setReason(e.detail.value)}
              >
                <IonSelectOption value="scheduled">
                  {t("scheduled")}
                </IonSelectOption>
                <IonSelectOption value="clogging">
                  {t("clogging")}
                </IonSelectOption>
                <IonSelectOption value="damaged">
                  {t("damaged")}
                </IonSelectOption>
                <IonSelectOption value="cleaning">
                  {t("cleaning")}
                </IonSelectOption>
                <IonSelectOption value="other">{t("other")}</IonSelectOption>
              </IonSelect>
            </IonItem>
          )}

          {/* Comment textarea */}
          {operation !== "install" && (
            <IonItem lines="full">
              <IonLabel position="floating">Comment</IonLabel>
              <IonTextarea
                rows={4}
                value={comment}
                onIonChange={(e) => setComment(e.detail.value!)}
              />
            </IonItem>
          )}

          {/* Area */}
          {operation !== "install" && (
            <IonItem
              button
              lines="full"
              onClick={handlePresentChooseHighlightsModal}
            >
              <IonLabel>
                <h2>Highlighted areas</h2>
                {!!highlightedAreas && (
                  <p>{highlightedAreas.split(",").join(", ")}</p>
                )}
              </IonLabel>
              <IonButton
                fill="outline"
                slot="end"
                style={{ pointerEvents: "none" }}
              >
                Choose areas
              </IonButton>
            </IonItem>
          )}

          {/* Photo */}
          {operation !== "install" && (
            <IonItem button lines="full" onClick={() => takePhoto()}>
              <IonLabel>
                <h2>Photo</h2>
              </IonLabel>

              {!!photo && (
                <IonThumbnail slot="end">
                  <IonImg src={photo.webPath} />
                </IonThumbnail>
              )}
              <IonButton
                fill="outline"
                slot="end"
                style={{ pointerEvents: "none" }}
              >
                Take photo
              </IonButton>
            </IonItem>
          )}

          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                disabled={!canSubmit}
                onClick={handleSubmit}
                color={
                  {
                    uninstall: "danger",
                    install: "success",
                    replace: "primary",
                  }[operation]
                }
              >
                {t(operation)}
              </IonButton>
              <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={"Loading"}
              />
            </IonCol>
          </IonRow>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default CreateTransaction;
