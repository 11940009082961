import classNames from "classnames";
import { areas } from "../lib/helpers";
import { Area } from "../models/filter-cloth";
import "./Heatmap.css";

interface HeatmapProps {
  highlightedHeatmapAreas: Area[];
  toggleHeatmapArea: (area: Area) => void;
}

const Heatmap: React.FC<HeatmapProps> = ({
  highlightedHeatmapAreas,
  toggleHeatmapArea,
}) => {
  const heatmapAreas = areas.map((area) => ({
    area,
    highlighted: !!highlightedHeatmapAreas?.includes(area),
  }));

  return (
    <div className="heatmap">
      <div className="heatmap__ar-wrapper">
        {/* Coordinates */}
        <div className="heatmap__coordinates">
          {/* X axis */}
          <div className="heatmap__x-axis">
            <p>A</p>
            <p>B</p>
            <p>C</p>
          </div>

          {/* Y axis */}
          <div className="heatmap__y-axis">
            <div className="heatmap__y-axis-item">
              <p>1</p>
            </div>

            <div className="heatmap__y-axis-item">
              <p>2</p>
            </div>

            <div className="heatmap__y-axis-item">
              <p>3</p>
            </div>
          </div>
        </div>

        <div className="heatmap__inner">
          <div className="heatmap__heatmap">
            {/* Background SVG */}
            <div className="heatmap__bg">
              <svg
                className="heatmap__svg"
                xmlns="http://www.w3.org/2000/svg"
                width={256}
                height={256}
                viewBox="0 0 67.733 67.733"
              >
                <path
                  strokeWidth={1.97812116}
                  d="M0 0v256h256V0H0zm48 16h160l32 32v160l-32 32H48l-32-32V48l32-32zm80 96a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16z"
                  transform="scale(.26458)"
                />
              </svg>
            </div>

            {/* Color & border overlay */}
            <div className="heatmap__color-overlay">
              {heatmapAreas.map(({ area, highlighted }, index) => (
                <span
                  key={area}
                  className={classNames("heatmap__color-tile", {
                    "is-highlighted": highlighted,
                    "heatmap__color-tile--border-right": index % 3 < 2,
                    "heatmap__color-tile--border-bottom": index < 6,
                  })}
                ></span>
              ))}
            </div>

            {/* Action overlay */}
            <div className="heatmap__action-overlay">
              <div className="heatmap__action-overlay-grid">
                {heatmapAreas.map(({ area }) => (
                  <button
                    key={area}
                    onClick={() => {
                      toggleHeatmapArea(area);
                    }}
                    className="heatmap__action-tile"
                  >
                    <span>Toggle area</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heatmap;
