import { gql } from "@apollo/client";
import { FilterPlate } from "./filter-plate";
import { Reason } from "./transaction";

export interface FilterCloth {
  id: string;
  fcid: string;
  modelNumber: string;
  installedTime: string;
  uninstalledTime: string;
  plate?: FilterPlate;
  installed: boolean;
  uninstallReason?: Reason;
  highlightedAreas?: string;
}

export type Area = "A1" | "B1" | "C1" | "A2" | "B2" | "C2" | "A3" | "B3" | "C3";

export const fragments = {
  filterCloth: gql`
    fragment FilterCloth on FilterCloth {
      id
      fcid
      modelNumber
      installed
      installedTime
      uninstalledTime
      uninstallReason
      highlightedAreas
    }
  `,
};
