import { History } from "history";
import { useEffect, useState } from "react";
import { FilterPlateSide, clothFieldMapping } from "../models/filter-plate";
import { useGetFilterClothByFcid } from "../operations/queries/filter-cloths";
import { useGetFilterPlate } from "../operations/queries/filter-plates";
import { useErrorToast } from "./useErrorToast";

export const useClothShortcut = (history: History) => {
  const [shortcutClothId, setShortcutClothId] = useState<string>("");
  const errorToast = useErrorToast();

  // Get new cloth once it's provided
  const { filterCloth } = useGetFilterClothByFcid(
    shortcutClothId,
    !shortcutClothId
  );

  // Also get the plate the cloth is on
  const {
    filterPlate,
    loading: filterPlateLoading,
    error: filterPlateError,
  } = useGetFilterPlate(filterCloth?.plate?.id ?? "", !filterCloth?.plate?.id);

  // Handle shortcut navigation
  useEffect(() => {
    if (!!filterCloth && !!history && !filterPlateLoading) {
      if (!filterPlateError) {
        if (!!filterPlate?.press) {
          // Find out press, plate and side for the scanned filter cloth
          const {
            press: { id: pressid },
            id: plateid,
          } = filterPlate;
          const side = (["front", "back", "single"] as FilterPlateSide[]).find(
            (side) =>
              filterPlate[clothFieldMapping.get(side)!]?.id === filterCloth.id
          );

          if (!!pressid && !!plateid && !!side) {
            // Jump to plate side
            history.push(`/create/${pressid}/${plateid}/${side}`);
            setShortcutClothId("");
          } else {
            errorToast(`Error! Couldn't jump to filter plate.`);
            setShortcutClothId("");
          }
        } else {
          errorToast(
            `Error! Couldn't jump to filter plate. Filter cloth is not installed.`
          );
          setShortcutClothId("");
        }
      } else {
        errorToast(
          `Error! Could not load filter plate connected to this filter cloth.`
        );
        setShortcutClothId("");
      }
    }
  }, [filterPlate, filterCloth, history, filterPlateLoading, filterPlateError]);

  return {
    setShortcutClothId,
  };
};
