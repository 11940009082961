import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { logoMicrosoft } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorToast } from "../lib/useErrorToast";
import { signIn } from "../operations/mutations/user";
import "./SignIn.css";

const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorToast = useErrorToast();

  async function handleClick() {
    // Reset state
    setError("");
    setLoading(true);

    // Start the signin flow
    const { status } = await signIn();

    // Handle signin method returning an error
    if (status === "error") {
      setLoading(false);
      setError(t("error-occured"));
    }
  }

  useEffect(() => {
    if (error) {
      errorToast(error);
    }
  }, [error, errorToast]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign in</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={handleClick}>
              <IonIcon slot="start" icon={logoMicrosoft} />
              Sign in
              {loading && (
                <IonSpinner slot="end" className="ion-margin-start" />
              )}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
