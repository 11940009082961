import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface GuardedRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

const GuardedRoute: React.FC<GuardedRouteProps> = ({
  isAuthenticated,
  component,
  ...props
}) => {
  const Component = component as React.ElementType;
  return (
    <Route
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
      }
      {...props}
    />
  );
};

export default GuardedRoute;
